import {axios} from "../index";

let reviewForms = Array.from(document.querySelectorAll('.review__form'));

reviewForms.forEach(($reviewForm) => {
    reviewForm($reviewForm)
})

function reviewForm($wrapper) {
    const productId = $wrapper.dataset.productId

    console.log(productId)

    const dataPrefix = 'data-review'

    const $name = $wrapper.querySelector(`[${dataPrefix}-name]`)
    const $text = $wrapper.querySelector(`[${dataPrefix}-item]`)
    const $button = $wrapper.querySelector(`[${dataPrefix}-button]`)

    $button.addEventListener('click', () => {
        if ($button.classList.contains('form-check__button--disabled')) {
            return
        }

        const author = $name.value
        const text = $text.value
        const rating = 5
        const language = document.getElementsByTagName('html')[0].getAttribute('lang');

        axios.post('/send-mail', {
            name: 'Отзыв о товаре',
            language,
            answers: [`Отзыв о товаре: ${text}`],
            recipient: ['marketing']
        }).then((response) => {
            axios.post(`/product/${productId}/send-review`, {
                author,
                text,
                rating
            })
                .then((response) => {
                    $name.value = ''
                    $text.value = ''
                    window.modals.close()
                    window.modals.open('success')
                })
                .catch((event) => {
                    console.log(event.response.data.message)

                    const $errorText = document.querySelector('[data-modal-error-message]')
                    $errorText.innerText = event.response.data.message
                    window.modals.close()
                    window.modals.open('error')
                });
        })
            .catch((event) => {
                console.log(event.response.data.message)

                const $errorText = document.querySelector('[data-modal-error-message]')
                $errorText.innerText = event.response.data.message
                window.modals.close()
                window.modals.open('error')
            });


    })
}
